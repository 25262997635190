import classNames from 'classnames';
import PropTypes from 'prop-types';

import { WELLNESS_SWIFTER_URL } from '@pumpkincare/config';
import { CAT } from '@pumpkincare/shared';
import {
  Body1,
  Body2,
  ButtonStyles,
  LegalBody,
  LoaderButton,
  Typography,
} from '@pumpkincare/shared/ui';
import { useGetSwifterToken } from '@pumpkincare/user';

import styles from './wellness-upsell.css';

const FOR_DOGS = { wellnessClubValue: '$19.95', savings: '$360' };

const FOR_CATS = { wellnessClubValue: '$14.95', savings: '$395' };

function WellnessUpsell({ petId, species }) {
  const { mutateAsync: getSwifterToken, isLoading: loadingSwifterToken } =
    useGetSwifterToken(petId);

  const benefits = species === CAT ? FOR_CATS : FOR_DOGS;

  function handleExplorePlansClick() {
    getSwifterToken(petId).then(token => {
      const url = `${WELLNESS_SWIFTER_URL}?token=${token}`;
      window.open(url, '_blank');
    });
  }

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.exploreSection}>
          <img
            src='/assets/images/iconImages/heart-clifford.png'
            alt=''
            className={styles.topIcon}
          />
          <span className={classNames(Typography.h4, styles.title)}>
            Join the Pumpkin Wellness Club to save hundreds on routine care, and more
          </span>

          <LegalBody className={styles.subTitle}>
            Get cash back and save on routine preventive care your pet needs to stay
            healthy. Access discounts on top pet brands. Join the club today for as
            low as {benefits.wellnessClubValue}/mo!
          </LegalBody>

          <LoaderButton
            className={classNames(ButtonStyles.primary, styles.exploreButton)}
            isLoading={loadingSwifterToken}
            onClick={handleExplorePlansClick}
          >
            Explore Plans
          </LoaderButton>
        </div>
        <div className={styles.entitlementsSection}>
          <div className={styles.entitlementsBox}>
            <div className={styles.boxHeader}>
              <Body1 isBold>
                Save up to {benefits.savings}+/year with your Pumpkin Wellness Club
                benefits
              </Body1>
            </div>
            <div className={styles.boxContent}>
              <LegalBody isBold>Reimbursable Vet Services</LegalBody>

              <div className={styles.entitlementItem}>
                <img src='/assets/images/iconImages/check-black.svg' alt='' />
                <Body2>Wellness Exams</Body2>
              </div>

              <div className={styles.entitlementItem}>
                <img src='/assets/images/iconImages/check-black.svg' alt='' />
                <Body2>Vaccinations</Body2>
              </div>

              <div className={styles.entitlementItem}>
                <img src='/assets/images/iconImages/check-black.svg' alt='' />
                <Body2>Health Screening Tests</Body2>
              </div>

              <div className={styles.entitlementItem}>
                <img src='/assets/images/iconImages/check-black.svg' alt='' />
                <Body2>Tick/Flea/Heartworm Prevention</Body2>
              </div>

              <div className={styles.entitlementItem}>
                <img src='/assets/images/iconImages/check-black.svg' alt='' />
                <Body2>Spay/Neuter OR Routine Dental Cleaning</Body2>
              </div>

              <LegalBody isBold className={styles.sectionTitle}>
                Freebies for Club Members{' '}
                <img
                  src='/assets/images/iconImages/design-star.png'
                  alt=''
                  className={styles.starIcon}
                />
              </LegalBody>

              <div className={styles.entitlementItem}>
                <img src='/assets/images/iconImages/check-black.svg' alt='' />
                <Body2>24/7 Pet Health Helpline</Body2>
              </div>

              <div className={styles.entitlementItem}>
                <img src='/assets/images/iconImages/check-black.svg' alt='' />
                <Body2>Access to Discounts on Top Pet Brands</Body2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

WellnessUpsell.propTypes = {
  petId: PropTypes.string.isRequired,
  species: PropTypes.string.isRequired,
};

export default WellnessUpsell;
